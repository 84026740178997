const observedRequests = [
  'reports-service/report',
  'configs-service/suite_config',
  'configs-service/filters?source=dashboard',
  'accounts/current_account',
];

const observedResources = ['mfe/static/js/main', 'mfe/remoteEntry'];

const isResourceTiming = (
  entry: PerformanceEntry
): entry is PerformanceResourceTiming => {
  return entry.entryType === 'resource';
};

const requestsObserverCallback = (list: PerformanceObserverEntryList) => {
  list.getEntries().forEach(entry => {
    const matchedRequest = observedRequests.find(req =>
      entry.name.includes(req)
    );
    if (matchedRequest) {
      window.newrelic?.addPageAction(`be-${matchedRequest}`, {
        duration: Math.round(entry.duration),
      });
    }

    const matchedResource = observedResources.find(resource =>
      entry.name.includes(resource)
    );
    if (
      matchedResource &&
      isResourceTiming(entry) &&
      entry.connectStart !== 0
    ) {
      const queuedTime = Math.round(entry.requestStart - entry.connectStart);
      window.newrelic?.addPageAction(`fe-res-${matchedResource}`, {
        queuedTime,
        duration: Math.round(entry.duration),
      });
    }
  });
};

export const requestsObserver = new PerformanceObserver(
  requestsObserverCallback
);
requestsObserver.observe({
  type: 'resource',
});
